<template>
        <el-dialog title="Pagini Statice" :visible.sync="showPopup" class="my-dialog-class" >
        <el-form label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='my-form' @submit.prevent="save" v-loading="loadingVisible" >
            <el-row :gutter="15" >
                
            <el-col :md='8'>
                <el-form-item prop="TitleRo" label='Titlu Ro' >
                    <el-input  class='full-width' v-model='selectedObject.TitleRo' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item prop="TitleEn" label='Titlu En' >
                    <el-input  class='full-width' v-model='selectedObject.TitleEn' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item prop="SlugRo" label='Slug Ro' >
                    <el-input  class='full-width' v-model='selectedObject.SlugRo' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item prop="SlugEn" label='Slug En' >
                    <el-input  class='full-width' v-model='selectedObject.SlugEn' />
                </el-form-item>
            </el-col>

            <el-col :md='24'>
                <el-form-item prop="ContinutRo" label='Continut Ro' >
                    <vue2-tinymce-editor v-model="selectedObject.ContinutRo"></vue2-tinymce-editor>
                </el-form-item>
            </el-col>
            <el-col :md='24'>
                <el-form-item prop="ContinutEn" label='Continut En' >
                    <vue2-tinymce-editor v-model="selectedObject.ContinutEn"></vue2-tinymce-editor>
                </el-form-item>
            </el-col>
            
            <el-col :md='8'>
                <el-form-item prop="MetaTitleRo" label='Meta Title Ro' >
                    <el-input  class='full-width' v-model='selectedObject.MetaTitleRo' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item prop="MetaTitleEn" label='Meta Title En' >
                    <el-input  class='full-width' v-model='selectedObject.MetaTitleEn' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item prop="MetaDescriptionRo" label='Meta Description Ro' >
                    <el-input  class='full-width' v-model='selectedObject.MetaDescriptionRo' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item prop="MetaDescriptionEn" label='Meta Description En' >
                    <el-input  class='full-width' v-model='selectedObject.MetaDescriptionEn' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item prop="MetaKeywordsRo" label='Meta Keywords Ro' >
                    <el-input  class='full-width' v-model='selectedObject.MetaKeywordsRo' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item prop="MetaKeywordsEn" label='Meta Keywords En' >
                    <el-input  class='full-width' v-model='selectedObject.MetaKeywordsEn' />
                </el-form-item>
            </el-col>
            <el-col :md='24'>
                <el-form-item prop="uploadPreview_Imagine" label='Imagine' >
                <el-upload
                        class='my-uploader'
                        :action="baseUrl + 'Pagini_statice/upload_Imagine'"
                        :show-file-list='false'
                        :on-success='handleUploadSuccess_Imagine'>
                            <i class='el-icon-plus my-uploader-icon'></i>
                        </el-upload>       
                        <img class="imagePreview" v-if='selectedObject.uploadPreview_Imagine' :src='selectedObject.uploadPreview_Imagine' > 
                </el-form-item>
            </el-col>            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer" >
            <el-button @click="showPopup=false"     > Renunta  </el-button>
            <el-button type="primary" @click="save" > Salveaza </el-button>
        </span>
    </el-dialog>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';
    import { Vue2TinymceEditor } from "vue2-tinymce-editor";

    export default {
        name: "Pagini_statice_dialog",
        extends: BasePage,
        components: {
            Vue2TinymceEditor
        },
        data () {
            return {
                baseUrl :'',
                showPopup: false,
                mode: 'add',
                selectedObject: {
                    TitleRo: '' , TitleEn: '' , SlugRo: '' , SlugEn: '' , MetaTitleRo: '' , MetaTitleEn: '' , MetaDescriptionRo: '' , MetaDescriptionEn: '' , MetaKeywordsRo: '' , MetaKeywordsEn: '' , Imagine: '' , uploadPreview_Imagine : '' ,                 },
                Info:{
                                    },
                rules: {
                    //   Nume: [ { required: true, message: "Campul este obligatoriu" } ]
                    TitleRo: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    TitleEn: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    SlugRo: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    SlugEn: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    MetaTitleRo: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    MetaTitleEn: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    MetaDescriptionRo: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    MetaDescriptionEn: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    MetaKeywordsRo: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    MetaKeywordsEn: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Imagine: [ { required: true, message: 'Campul este obligatoriu' } ], 
                }
            }
        },
        methods: {
            show_me: async function( id ) {
                this.showPopup        = true;
                if( id == null )
                {
                    this.mode = "add";
                    this.selectedObject = {};
                }
                else
                {
                    this.mode             = "edit";
                    var            result = await this.post("pagini_statice/get_info_item_dialog", { id: id } );
                    this.selectedObject   = result.Item;
                }
            },
            async get_info(){
                var response = await this.post("pagini_statice/get_info_for_dialog" );
                            },
             handleUploadSuccess_Imagine(response){
                    this.selectedObject.Imagine = response.FileName;
                    this.selectedObject.uploadPreview_Imagine  = response.Url;
                },
                    save: async function() {
                this.$refs['my-form'].validate( async(valid) => {
                    if (valid)
                    {
                        await this.post("pagini_statice/save", { mode: this.mode, object: this.selectedObject } );
                        this.showPopup = false;
                        this.$emit("save");
                    }
                });
            }
        },
        mounted: function() {
            this.baseUrl = settings.BASE_URL;
            this.get_info();
        }
    };
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }

    .imagePreview {
        width: 150px;
        height: 150px;
        object-fit: contain;
    }

</style>